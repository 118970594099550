import { GetStaticProps } from 'next';
import { ReactElement } from 'react';
import { NextSeo } from 'next-seo';
import { NextPageWithLayout } from './_app';
import Layout from '../layouts';
import { serialize } from '../utilities/richText';

const Home: NextPageWithLayout = ({ content }: any) => {
  return (
    <>
      <NextSeo
        title="QueensLab Resumes"
        description="Find the perfect team and explore the unknown"
      />
      <main className="flex items-center flex-col mt-4 md:mt-16">
        <h1 className="text-2xl md:text-6xl mb-6">{content.title}</h1>
        <div>{serialize(content.description)}</div>
      </main>
    </>
  );
};

Home.getLayout = (page: ReactElement) => {
  return <Layout>{page}</Layout>;
};

export const getStaticProps: GetStaticProps = async (ctx) => {
  const response = await fetch(
    `${process.env.CMS_BASEURL}/api/globals/home-page?locale=${ctx.locale}&draft=${ctx.preview}`,
    {
      headers: {
        Authorization: `users API-Key ${process.env.CMS_API_KEY}`
      }
    }
  );
  if (!response.ok) {
    return { props: { content: {} } };
  }

  const content = await response.json();

  return {
    props: {
      content
    }
  };
};

export default Home;
